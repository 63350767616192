export const operators = [
	"ALL",
	"AND",
	"ANY",
	"OR",
	"ROW",
	"(",
	")",
	"=",
	"DOES NOT =",
	"<=",
	">=",
	"<",
	">",
	"+",
	"-",
	"x",
	"÷",
	"CONTAINS",
	"DOES NOT CONTAIN",
	"CONTAINS NUMBER",
	"DOES NOT CONTAIN NUMBER",
	"CONTAINS SYMBOL",
	"DOES NOT CONTAIN SYMBOL",
	"CONTAINS LETTER",
	"DOES NOT CONTAIN LETTER",
	"IS EMPTY",
	"IS NOT EMPTY",
	"LEFT",
	"RIGHT",
	"LENGTH",
	"DISTINCT COUNT"
]; 