import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import navigationItemReferences from "../constants/navigation-item-references";
import pages from "../constants/pages";
import {
	selectGroupChildren, selectInitialState, selectNavState,
	selectOrganizationChildren, selectPluginChildren, selectRoleChildren,
	setExpandedItems, setNavState, setSelectedItem
} from "../redux/navigationSlice";
import { selectOrganizationIdentityProvider, selectOrganizationSsoAndScim } from "../redux/userSlice";

import useAuthorization from "./useAuthorization";

/**
 * Currently, our general navigation strategy is comprised of three main aspects:
 * 1. Updating the selected item state in the navigation drawer tree.
 * 2. Updating the expanded items state in the navigation drawer tree.
 * 3. Updating the navigation state in the navigation drawer tree for pages that have dynamic children.
 * @returns {Object} object containing functions to handle navigation events in the CoApp MP
 */
export default function useCoAppNavigation() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const navState = useSelector(selectNavState);
	const navInitialState = useSelector(selectInitialState);
	const groupChildren = useSelector(selectGroupChildren);
	const pluginChildren = useSelector(selectPluginChildren);
	const organizationChildren = useSelector(selectOrganizationChildren);
	const roleChildren = useSelector(selectRoleChildren);
	const organizationSsoAndScim = useSelector(selectOrganizationSsoAndScim);
	const identityProviderName = useSelector(selectOrganizationIdentityProvider);
	const hasUserAccess = useAuthorization(["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"], true);

	/**
	 * Handles updating the navigation state when a user updates a resource name or deletes a resource in the group or role page
	 * @param {*} isDeleteEvent flag to determine if the caller is calling after a deletion event
	 * @param {*} openedFrom resource the user is coming from
	 * @param {*} nameInput updated name of the resource
	 * @param {*} pathname pathname to redirect user to after updating a resource name
	 */
	const updateCoAppNavigation = (isDeleteEvent = false, openedFrom, nameInput = "", pathname = "") => {
		const newNavState = [...navState];
		if (!isDeleteEvent) {
			let copyGroupChildren = [...newNavState[openedFrom].children].slice(1); //update nav state to reflect resource name update
			copyGroupChildren.unshift({
				id: Math.random(),
				name: nameInput,
				children: [],
				isGroupingLabel: true
			});

			newNavState[openedFrom] = {
				...newNavState[openedFrom],
				children: copyGroupChildren,
				isChangeEvent: true,
				isStillSelected: true,
				selectedResource: "Users",
				isNameChangeEvent: true
			};
		} else {
			newNavState[openedFrom] = {
				...newNavState[openedFrom],
				children: [],
				isStillSelected: true,
				selectedResource: ""
			};
		}
		dispatch(setNavState(newNavState));

		if (!isDeleteEvent) {
			navigate(pathname, { state: { name: nameInput }, replace: true });
		}
	};

	/**
	 * Handles navigation events that involve viewing a user's profile page
	 * Note: When the user presses the back button, the resource has already been cleared from a previous event so no
	 * children are present. The else case handles the expansion of the parent resource and selection of the user nav item
	 * @param {*} params used for dressing up the selected nav item for the navigation drawer
	 * @param {*} openedFrom used to determine which resource the user is coming from
	 * @param {*} userId id of the user to be viewed
	 * @returns updated nav state array for the rerender
	 */
	const getDeepestNavState = (params, openedFrom, userId) => {
		let newNavState = [...navState];
		newNavState[openedFrom] = {
			...newNavState[openedFrom]
		};
		let selectedObj;
		if (newNavState[openedFrom].children.length > 0) {
			newNavState[openedFrom].children = newNavState[openedFrom].children.map(resource => {
				if (resource.name === "Users") {
					let temp = { ...resource };
					selectedObj = {
						id: 999,
						name: params.row.firstname && params.row.lastname ? `${params.row.firstname} ${params.row.lastname}` : params.row.email,
						children: [],
						keepOpen: true,
						isClickable: true,
						isUserNavigationItem: true,
						page: pages.profile + userId,
						firstname: params.row.firstname,
						lastname: params.row.lastname,
					};
					temp.children = [selectedObj];
					return temp;
				}
				return resource;
			});
		} else {
			selectedObj = {
				id: 999,
				name: params.row.firstname && params.row.lastname ? `${params.row.firstname} ${params.row.lastname}` : params.row.email,
				children: [],
				keepOpen: true,
				isClickable: true,
				isUserNavigationItem: true,
				page: pages.profile + userId,
				firstname: params.row.firstname,
				lastname: params.row.lastname,
			};
			let resourceChildren = openedFrom === 7 ? groupChildren : roleChildren;
			let temp = resourceChildren.map((child) => {
				if (child.name === "Add/Remove IdP Groups" && organizationSsoAndScim) {
					return {
						...child,
						name: identityProviderName ? `Add/Remove ${identityProviderName} Groups` : "Add/Remove IdP Groups",
						page: handlePageGeneration(child.name, openedFrom === 7 ? "Groups" : "Roles", params.row.parentResourceId),
						keepOpen: true,
						isClickable: true
					};
				} else if (child.name === "Users") {
					return {
						...child,
						page: handlePageGeneration(child.name, openedFrom === 7 ? "Groups" : "Roles", params.row.parentResourceId),
						children: [selectedObj],
						keepOpen: true,
						isClickable: true
					};
				} else {
					return {
						...child,
						page: handlePageGeneration(child.name, openedFrom === 7 ? "Groups" : "Roles", params.row.parentResourceId),
						keepOpen: true,
						isClickable: true
					};
				}
			});
			temp.unshift({
				id: Math.random(),
				name: params.row.parentName,
				children: [],
				isGroupingLabel: true
			});
			temp = temp.filter(child => {
				if (!organizationSsoAndScim && child.name.includes("Add/Remove")) {
					return false;
				} else {
					return true;
				}
			});
			newNavState[openedFrom].children = temp;
		}
		return newNavState;
	};

	/**
	 * Handles viewing a user's profile page from the group or role page.
	 * @param {*} params state of the user being viewed
	 * @param {*} openedFrom resource the user is coming from
	 * @param {*} parentId id of the parent resource, used when a user traverses browser history so the sub resources can be retrieved from our services
	 * @param {*} parentName name of the parent resource, used when a user traverses browser history so the navigation drawer displays the correct name when expanded
	 */
	const viewUserPage = (params, openedFrom, parentId, parentName) => {
		let newNavState = [...navState];
		newNavState[openedFrom] = {
			...newNavState[openedFrom]
		};
		let selectedObj;
		newNavState[openedFrom].children = newNavState[openedFrom].children.map(resource => {
			if (resource.name === "Users") {
				let temp = { ...resource };
				selectedObj = {
					id: 999,
					name: params.row.firstname && params.row.lastname ? `${params.row.firstname} ${params.row.lastname}` : params.row.email,
					children: [],
					keepOpen: true,
					isClickable: true,
					isUserNavigationItem: true,
					page: pages.profile + params.row.id,
					firstname: params.row.firstname,
					lastname: params.row.lastname,
					parentResourceId: parentId,
					parentName: parentName
				};
				temp.children = [selectedObj];
				return temp;
			}
			return resource;
		});

		dispatch(setSelectedItem(selectedObj));
		dispatch(setExpandedItems([(openedFrom + navigationItemReferences.navItemIndexToNavItemIdOffset).toString(), openedFrom === 7 ? "14" : "17"]));
		dispatch(setNavState(newNavState));
		navigate(pages.profile + params.row.id + `?from=${openedFrom === 7 ? "g" : "r"}`, { state: selectedObj });
	};

	/**
	 * Used in navigation state reconstruction when a user traverses the browser history.
	 * @param {*} params state of the user being viewed
	 * @returns [newNavState, selectedObj] array containing the updated nav state and the object to be selected
	 */
	const getUserNavState = (params) => {
		let newNavState = [...navState];
		let selectedObj = {
			id: 999,
			name: params.row.firstname && params.row.lastname ? `${params.row.firstname} ${params.row.lastname}` : params.row.email,
			children: [],
			keepOpen: true,
			isClickable: true,
			firstname: params.row.firstname,
			lastname: params.row.lastname,
			email: params.row.email,
			page: pages.profile + params.row.id,
		};
		newNavState[6] = { // User management is the 6th item in the nav array
			...newNavState[6],
			children: [selectedObj]
		};
		return [newNavState, selectedObj];
	};

	/**
	 * Handles updating the navigation state when a user navigates to a user page from the user management page
	 * @param {*} params state of the user being viewed
	 */
	const viewUserPageFromUserManagement = (params) => {
		let newNavState = [...navState];
		let selectedObj = {
			id: 999,
			name: params.row.firstname && params.row.lastname ? `${params.row.firstname} ${params.row.lastname}` : params.row.email,
			children: [],
			keepOpen: true,
			isClickable: true,
			firstname: params.row.firstname,
			lastname: params.row.lastname,
			email: params.row.email,
			page: pages.profile + params.id,
		};
		newNavState[6] = { // User management is the 6th item in the nav array
			...newNavState[6],
			children: [selectedObj]
		};
		dispatch(setNavState(newNavState));
		dispatch(setSelectedItem(selectedObj));
		dispatch(setExpandedItems(["8"]));
		navigate(pages.profile + params.id + "?from=um", { state: selectedObj });
	};

	/**
	 * Retrieves the children of the resource being expanded so that the navigation state can be updated accordingly
	 * @param {*} navItemId id of the nav node being expanded
	 * @returns parent resources children
	 */
	const getResourceChildren = (navItemId) => {
		switch (navItemId) {
			case navigationItemReferences.organizationNavItemIndex:
				return organizationChildren;
			case navigationItemReferences.groupNavItemIndex:
				return groupChildren;
			case navigationItemReferences.roleNavItemIndex:
				return roleChildren;
			case navigationItemReferences.pluginNavItemIndex:
				return pluginChildren;
			default:
				return [];
		}
	};

	/**
	 * Handles generating the pages for the children being added to the navigation state
	 * @param {*} childName name of the child the page is being generated for
	 * @param {*} parentName parent resource name, used to configure role/group specific pages
	 * @param {*} childId id of the child resource, ie the group, role or plugin id
	 * @returns 
	 */
	const handlePageGeneration = (childName, parentName, childId) => {
		switch (childName) {
			case "Users":
				return parentName === "Roles" ? `/settings/roles/${childId}/users` : `/settings/groups/${childId}/users`;
			case "Permissions":
				return `/settings/roles/${childId}/permissions`;
			case "Add/Remove IdP Groups":
				return parentName === "Roles" ? `/settings/roles/${childId}/sso` : `/settings/groups/${childId}/sso`;
			case "Groups":
				return `/settings/plugins/${childId}/groups`;
			case "Rules":
				return `/settings/groups/${childId}/rules`;
			case "Plugins":
				return `/settings/groups/${childId}/plugins`;
			default:
				return "/settings/organization";
		}
	};

	const handleParentPageGeneration = (parentName, parentId) => {
		switch (parentName) {
			case "Roles":
				return `/settings/roles/${parentId}`;
			case "Groups":
				return `/settings/groups/${parentId}`;
			case "Plugins":
				return `/settings/plugins/${parentId}`;
			default:
				return "/settings/organization";
		}
	};

	/**
	 * Used in navigation state reconstruction when a user traverses the browser history.
	 * @param {*} openedFrom resource the user is coming from
	 * @param {*} parentName name of the parent resource, ie. group, role, etc.
	 * @param {*} params state of the resource being viewed
	 * @returns updated nav state object
	 */
	const getUpdatedNavState = (openedFrom, parentName, params) => {
		let newNavState = [...navState];
		let resourceChildren = getResourceChildren(openedFrom);

		newNavState[openedFrom] = {
			...newNavState[openedFrom],
			children: [
				{
					id: Math.random(),
					name: params.row.name,
					children: [],
					isGroupingLabel: true,
					hasHomePage: params.row.hasHomePage,
					page: ""
				},
				...resourceChildren.map((child) => {
					if (child.name === "Add/Remove IdP Groups" && organizationSsoAndScim) {
						return {
							...child,
							name: identityProviderName ? `Add/Remove ${identityProviderName} Groups` : "Add/Remove IdP Groups",
							page: handlePageGeneration(child.name, parentName, params.id),
							keepOpen: true,
							isClickable: true
						};
					} else {
						return {
							...child,
							page: handlePageGeneration(child.name, parentName, params.id),
							keepOpen: true,
							isClickable: true
						};
					}
				}).filter(child => {
					if (!organizationSsoAndScim && child.name.includes("Add/Remove")) {
						return false;
					} else {
						return true;
					}
				})
			],
			isParentChangeEvent: true
		};
		return newNavState;
	};

	/**
	 * Handles redirecting a user to the edit view of a resource, ie. group, role, plugin
	 * @param {*} params state of the resource that is being edited
	 * @param {*} openedFrom index of resource in the nav state array
	 * @param {*} parentName parent resource name
	 */
	const openResourceEditPage = (params, openedFrom, parentName) => {
		let newNavState = [...navState];
		let resourceChildren = getResourceChildren(openedFrom);
		newNavState[openedFrom] = {
			...newNavState[openedFrom],
			children: [
				{
					id: Math.random(),
					name: params.row.name,
					children: [],
					isActive: params.row?.active,
					isGroupingLabel: true,
					isSuperAdmin: params.row?.isSuperAdmin,
					hasHomePage: false,
					page: ""
				},
				...resourceChildren.map((child) => {
					if (child.name === "Add/Remove IdP Groups" && organizationSsoAndScim) {
						return {
							...child,
							name: identityProviderName ? `Add/Remove ${identityProviderName} Groups` : "Add/Remove IdP Groups",
							page: handlePageGeneration(child.name, parentName, params.id),
							keepOpen: true,
							isClickable: true
						};
					} else {
						return {
							...child,
							page: handlePageGeneration(child.name, parentName, params.id),
							keepOpen: true,
							isClickable: true
						};
					}
				}).filter(child => {
					if (!organizationSsoAndScim && child.name.includes("Add/Remove")) {
						return false;
					} else {
						return true;
					}
				})
			]
		};

		dispatch(setNavState(newNavState));
		dispatch(setSelectedItem(newNavState[openedFrom].children[1]));
		dispatch(setExpandedItems([(openedFrom + navigationItemReferences.navItemIndexToNavItemIdOffset).toString()]));
		navigate(newNavState[openedFrom].children[1].page, { state: newNavState[openedFrom].children[0] });
	};

	const handleOpenResourceHomePage = (openedFrom, parentName, params, isRebuild = false) => {
		let newNavState = [...navState];
		let resourceChildren = getResourceChildren(openedFrom);
		let tempResourceChildren = [...resourceChildren];

		tempResourceChildren[0] = {
			...tempResourceChildren[0],
			page: handlePageGeneration(tempResourceChildren[0].name, parentName, isRebuild ? params.resourceId : params.id),
			isClickable: true,
			isGroupingLabel: false,
			hasHomePage: false
		};

		newNavState[openedFrom] = {
			...newNavState[openedFrom],
			children: [
				{
					id: 999,
					name: isRebuild ? params.name : params.row.name,
					children: tempResourceChildren,
					isActive: isRebuild ? params.isActive : params.row?.active,
					isGroupingLabel: true,
					isSuperAdmin: isRebuild ? params.isSuperAdmin : params.row?.isSuperAdmin,
					page: isRebuild ? handleParentPageGeneration(parentName, params.resourceId) : handleParentPageGeneration(parentName, params.id),
					hasHomePage: true,
					resourceId: params.id
				}
			]
		};

		dispatch(setNavState(newNavState));
		dispatch(setSelectedItem(newNavState[openedFrom].children[0]));
		dispatch(setExpandedItems(["12", "999"])); //harcoded for plugins only due to not supporting homepages for other resource groupings
		if (!isRebuild) {
			navigate(newNavState[openedFrom].children[0].page, { state: newNavState[openedFrom].children[0] });
		}
	};

	/**
	 * Handles opening the scim wizard and updating the navigation state accordingly.
	 */
	const openScimWizard = (doNavigation = true) => {
		let newNavState = [...navState];
		const organizationChildren = getResourceChildren(5);
		newNavState[5] = {
			...newNavState[5],
			children: organizationChildren,
			keepOpen: true
		};
		dispatch(setNavState(newNavState));
		dispatch(setSelectedItem(newNavState[5].children[0]));
		dispatch(setExpandedItems(["7"]));
		if (doNavigation) navigate(pages.scimWizard);
	};

	/**
	 * Handles reconstructing the navigation state when a user navigates to a parent page from traversing the browser history.
	 * @param {*} pathItems array of url path items
	 */
	const reconstructParentPage = (pathItems) => {
		let reconstructedPage = `/${pathItems[0]}/${pathItems[1]}`;
		let foundNode;
		const isRuleEditing = pathItems[0] === "rules" && !pathItems[1].includes("library");
		if (reconstructedPage === "/rules/trash") {
			foundNode = navInitialState.selectedItem.children[0];
		} else if (reconstructedPage === "/rules/new") {
			foundNode = navInitialState.navState[1];
		} else if (reconstructedPage === "/rules/library" || isRuleEditing) {
			foundNode = navInitialState.selectedItem;
		} else {
			foundNode = navInitialState.navState.filter(node => node.page === reconstructedPage)[0];
		}
		let tempSelected = {
			id: foundNode.id,
			name: foundNode.name,
			children: foundNode.children,
			page: foundNode.page,
		};
		dispatch(setSelectedItem(tempSelected));
		const isLibraryPage = ["/rules/library", "/rules/trash"].includes(reconstructedPage);
		if ((isLibraryPage || isRuleEditing) && reconstructedPage !== "/rules/new") {
			dispatch(setExpandedItems(["3"]));
		} else {
			dispatch(setExpandedItems([]));
		}
	};

	/**
	 * Handles reconstructing the navigation state when a user navigates to a sub resource page from traversing the browser history.
	 * @param {*} pathItems  array of url path items
	 * @param {*} location location used to restore state
	 */
	const reconstructSubResourcePage = (pathItems, location) => {
		let reconstructedBasePage = `/${pathItems[0]}/${pathItems[1]}`;
		let reconstructedFullPage = `/${pathItems[0]}/${pathItems[1]}/${pathItems[2]}/${pathItems[3]}`;
		const foundParentNode = navInitialState.navState.filter(node => node.page === reconstructedBasePage)[0];
		let parentResourceName = pathItems[1].substring(0, pathItems[1].length - 1);
		let subResourceName = pathItems[3];
		subResourceName = subResourceName.charAt(0).toUpperCase() + subResourceName.slice(1);
		let children = Object.entries(navInitialState).filter(([key, value]) => key === `${parentResourceName}Children`)[0][1];
		let params = {
			id: pathItems[2],
			row: {
				id: location.state.id,
				name: location.state.name,
				children: location.pathname.includes("plugins") ? location.state.children : [],
				page: reconstructedFullPage,
				hasHomePage: location.state.hasHomePage,
			}
		};

		let sel = children.filter(child => child.name === subResourceName)[0];

		let tempSelected = {
			id: sel.id,
			name: location.state.name,
			children: [],
			page: reconstructedFullPage,
		};

		let parent = pathItems[1].charAt(0).toUpperCase() + pathItems[1].slice(1);
		const openedFrom = pathItems[1].includes("group") ? navigationItemReferences.groupNavItemIndex : pathItems[1].includes("plugins") ? navigationItemReferences.pluginNavItemIndex : navigationItemReferences.roleNavItemIndex;
		let newNavState = getUpdatedNavState(openedFrom, parent, params);

		if (location.pathname.includes("plugins") && location.state.children.length > 0) {
			let tempNavState = [...newNavState];
			let newChildren = [];
			//if the parent node has no children, we need to add the child to the parent node for the plugin page
			if (tempNavState[openedFrom].children[0].children.length === 0) {
				newChildren.push({
					...location.state,
					children: location.state.children
				});
			} else {
				newChildren = [...location.state.children];
			}
			tempNavState[openedFrom] = {
				...tempNavState[openedFrom],
				children: newChildren
			};
			dispatch(setNavState(tempNavState));
			dispatch(setSelectedItem(location.state.children[0]));
			dispatch(setExpandedItems(["12", "999"]));
		} else {
			dispatch(setNavState(newNavState));
			dispatch(setExpandedItems([(foundParentNode.id).toString()]));
			dispatch(setSelectedItem(tempSelected));
		}
	};

	/**
	 * Handles reconstructing the navigation state when a user navigates to a user page from traversing the browser history.
	 * Note: Due to the user profile being accessible via multiple locations in the UI, the way we reconstruct the user page varies based on 
	 * the location the user is coming from. The from parameter is used to determine the location
	 * ?from=g: User is coming from the group page
	 * ?from=r: User is coming from the role page
	 * ?from=um: User is coming from the user management page
	 * default case handles when a user navigated to the user page via the coapp profile component
	 * @param {*} pathItems array of url path items
	 * @param {*} location state used to restore the user page
	 */
	const reconstructUserPage = (pathItems, location) => {
		if (!hasUserAccess) return;
		const from = location.search.split("=")[1];
		let userObj = {
			row: {
				...location.state
			}
		};
		let restoredNavState;
		switch (from) {
			case "g":
				restoredNavState = getDeepestNavState(userObj, 7, pathItems[2]);
				dispatch(setNavState(restoredNavState));
				dispatch(setSelectedItem(location.state));
				dispatch(setExpandedItems(["9", "14"]));
				break;
			case "r":
				restoredNavState = getDeepestNavState(userObj, 8, pathItems[2]);
				dispatch(setNavState(restoredNavState));
				dispatch(setSelectedItem(location.state));
				dispatch(setExpandedItems(["10", "17"]));
				break;
			case "um":
				restoredNavState = getUserNavState(userObj);
				dispatch(setNavState(restoredNavState[0]));
				dispatch(setSelectedItem(location.state));
				dispatch(setExpandedItems(["8"]));
				break;
			default:
				restoredNavState = getUserNavState(userObj);
				dispatch(setNavState(restoredNavState[0]));
				dispatch(setSelectedItem(restoredNavState[1]));
				dispatch(setExpandedItems(["8"]));
				break;
		}
	};

	/**
	 * Updates navigation drawer state when user opens the rule wizard from rule library.
	 */
	const openRuleWizard = () => {
		navigate(pages.newRule);
		dispatch(setExpandedItems([""]));
		dispatch(setSelectedItem(navInitialState.navState[1]));
	};


	return {
		openResourceEditPage,
		getUpdatedNavState,
		getDeepestNavState,
		getUserNavState,
		handleOpenResourceHomePage,
		openRuleWizard,
		openScimWizard,
		reconstructParentPage,
		reconstructSubResourcePage,
		reconstructUserPage,
		updateCoAppNavigation,
		viewUserPage,
		viewUserPageFromUserManagement
	};
}