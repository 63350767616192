import {
	Dialog, DialogActions, DialogContent, DialogTitle, Divider,
	FormControl, FormHelperText, InputLabel, MenuItem, Stack
} from "@mui/material";

import CoAppTextField from "../../global/components/inputs/CoAppTextField";
import { CoAppActionButton, CoAppCancelTextButton, CoAppCloseIcon, CoAppInputLabel, CoAppSelect } from "../../global/styled/global.styled";


export default function TriggerDialog(props) {
	return (
		<Dialog open={props.openDialogFlag} onClose={props.cancelDialogHandler}>

			<DialogActions sx={{ justifyContent: "space-between" }}>
				<DialogTitle sx={{ fontWeight: "700" }}>{props.openAsEditFlag ? "Edit Trigger" : "Add Trigger"}</DialogTitle>
				<CoAppCloseIcon top={10} left={282} onClick={props.cancelHandler}></CoAppCloseIcon>
			</DialogActions>

			<Divider sx={{ width: "100%" }} />

			<DialogContent sx={{ width: "325px" }}>
				<InputLabel id="triggerName"></InputLabel>
				<Stack spacing={2}>
					<CoAppTextField
						error={props.triggerNameValueError !== ""}
						fullWidth
						helperText={props.triggerNameValueError}
						inputProps={{ maxLength: 255 }}
						label="Name this trigger"
						labelId="triggerName"
						onChange={props.triggerNameInputHandler}
						sx={{ margin: 0 }}
						type='text'
						variant="outlined"
						value={props.nameOfTrigger}
					/>

					<Stack spacing={2}>
						<FormControl sx={{ display: "block" }} error={props.triggerFieldValueError !== ""}>
							<CoAppInputLabel id="triggerField">Field</CoAppInputLabel>
							<CoAppSelect
								labelId="triggerField"
								label="Field"
								onChange={(e) => props.fieldDropdownSelectionChangeHandler(e, JSON.parse(e.target.value))}
								sx={{ width: "100%" }}
								value={props.nameOfTriggerField}
							>
								{props.allTriggerFields
									.slice()
									.sort((a, b) => a.name.localeCompare(b.name))
									.map(item => (
										<MenuItem key={item.id} value={JSON.stringify(item)}> 
											{item.name}
										</MenuItem>
									))
								}
							</CoAppSelect>
							<FormHelperText>{props.triggerFieldValueError}</FormHelperText>
						</FormControl>

						{props.showTriggerType && (
							<FormControl sx={{ display: "block" }} error={props.triggerTypeValueError !== ""}>
								<CoAppInputLabel id="triggerType">Trigger Type</CoAppInputLabel>
								<CoAppSelect
									labelId="triggerType"
									label="Trigger Type"
									onChange={props.triggerTypeChangeHandler}
									sx={{ width: "100%" }}
									value={props.clickAction}
									required={props.showTriggerType}
								>
									<MenuItem value={false}>Field Value</MenuItem>
									<MenuItem value={true}>Click Action</MenuItem>
								</CoAppSelect>
								<FormHelperText>{props.triggerTypeValueError}</FormHelperText>
							</FormControl>
						)}
					</Stack>
				</Stack>
			</DialogContent>

			<Divider sx={{ width: "100%" }} />

			<DialogActions >
				<CoAppCancelTextButton onClick={props.cancelDialogHandler}>Cancel</CoAppCancelTextButton>
				<CoAppActionButton variant='outlined' disabled={props.formHasErrors}
					onClick={props.openAsEditFlag ? props.saveEditHandler : props.saveHandler}>Save</CoAppActionButton>
			</DialogActions>

		</Dialog >
	);
}